import { getScript } from '@/br-jquery';
import { captureMessage, Severity } from '@sentry/browser';

let previousRequest: JQuery.jqXHR<string | undefined> | null;

// We're only interested in the latest news, and we want to avoid the
// possibility of stale content arriving *after* the fresh content.
const cancelOutstandingRequest = function(): void {
	if (previousRequest == null) { return; }
	console.debug('Cancelling previous request');
	previousRequest.abort();
	previousRequest = null;
};

/**
 * Returns the current document's language, or if that's not available, the
 * language of the user's browser.
 */
export const language = ((): () => string => {
	return (): string => {
		return document.documentElement.lang || navigator.language;
	};
})();


const currentUrlWithCacheBuster = function(timestamp: number) {
	if (timestamp == null) { return document.location.href; }

	const search = document.location.search.length > 1 ?
		`${ document.location.search }&ts=${ timestamp }&lang=${ language() }`
	:
		`?ts=${ timestamp }&lang=${ language() }`;

	return document.location.origin + document.location.pathname + search;
};

export async function refresh(timestamp: number): Promise<void> {
	console.debug('Refreshing page ...', timestamp);
	cancelOutstandingRequest();
	previousRequest = getScript(currentUrlWithCacheBuster(timestamp)).fail(() => {
		captureMessage("refresh failed", Severity.Warning)
		document.location.reload();
	})

	let currentRequest = previousRequest;
	while (currentRequest != null) {
		await currentRequest;
		if (currentRequest === previousRequest) {
			document.dispatchEvent(new CustomEvent('br:page:refresh'));
			return;
		}
		console.debug('Request was cancelled, waiting for new request to resolve');
		currentRequest = previousRequest;
	}
}

